import React, { useEffect, useState } from 'react';

import { request } from 'utils/api';
import { unwrapContentfulFields } from 'common/components';
import { LandingPage } from 'public/components';

import Home from '../Home';
import CustomLandingPage from '../CustomLandingPage';

export default function Preview({ match }) {
  const [entry, setEntry] = useState(null);
  const params = match.params;

  useEffect(() => {
    if (params?.entryId) {
      request({
        method: 'GET',
        path: `/1/preview/${params.env}/${params.entryId}`,
      }).then(({ entry }) => {
        setEntry(entry);
      });
    }
  }, [params?.entryId]);

  if (!entry) return null;

  if (entry.sys.contentType.sys.id === 'lp') {
    return <CustomLandingPage page={unwrapContentfulFields(entry)} />;
  }

  if (entry.sys.contentType.sys.id === 'landingPage') {
    return <LandingPage object={entry} />;
  }

  return <Home entry={unwrapContentfulFields(entry)} />;
}
